import React from 'react'
import { Link, graphql } from "gatsby"


import Layout from '../components/layout'
import SEO from '../components/seo'
import Sidebar from '../components/sidebar'

import styled from '@emotion/styled'

const Container = styled.div`
	padding-left: 0;
	padding-right: 0;
	@media screen and (max-width: 1023px){
		padding-left: 1rem;
		padding-right: 1rem;
	}
`
const SidebarCol = styled.div`
	grid-column: 2 / span 3;
	@media screen and (max-width: 1023px){
		display: none;
	}
`

const AboutWrapper = styled.div`
	grid-column: 6 / span 6;
	grid-row: 1 / 4;
	@media screen and (max-width: 767px){
		grid-column: 1 / span 4;
	}
	@media screen and (min-width: 768px) and (max-width: 1023px){
		grid-column: 2 / span 6;
	}
`
const AboutContent = styled.div`
	line-height: 1.5;
	.link-orange{
		color: var(--c-tertiary);
	}
	.link-purple{
		color: var(--c-primary);
	}
	a{
		font-weight: 500;
		text-decoration: none;
		&:hover{
			text-decoration: underline;
		}
	}
	ol{
		padding-left: 0;
		li{
			margin-bottom: 0;
		}
	}
`

const AboutPage = (props) => {
	return (
		<Layout>
			<SEO 
				pageTitle="About"
				pageDesc="Rizqi Nizamil Putra is a husband, father of a little angel and Front-end Designer from Indonesia (GMT +7). Passionate about user centred design, accessibility, problem solving and absolutely CSS."
			/>
			<Container className="container">
				<SidebarCol><Sidebar headingText="Family, design, code" /></SidebarCol>
				<AboutWrapper>
					<AboutContent>
						<p>
						Rizqi Nizamil Putra is a husband, father of a little angel and <a className="link-orange" href="http://bradfrost.com/blog/post/frontend-design/" target="_blank" rel="noopener noreferrer">Front-end Designer</a> from Indonesia (GMT +7). Passionate about user centred design, accessibility, problem solving and absolutely CSS.
						</p>
						<p>
						I’m currently looking for full-time remote work, and my real résumé is available upon request.
						</p>
						<p>
						Most of my career experiences were working on the agency environment since 2013. I’ve ever worked with <a className="link-purple" href="https://bluelounge.com" target="_blank" rel="noopener noreferrer">Bluelounge</a>, <a className="link-orange" href="https://pr360global.com" target="_blank" rel="noopener noreferrer">PR360Global</a>, <a className="link-purple" href="https://thelittledoor.com" target="_blank" rel="noopener noreferrer">TheLittleDoor</a>, <a className="link-orange" href="https://rocketon.app" target="_blank" rel="noopener noreferrer">RocketOn</a> and many more. 
						</p>

						<p>Nowadays front-end developer skill become biased. <a className="link-purple" href="https://increment.com/frontend/when-frontend-means-full-stack/" target="_blank" rel="noopener noreferrer">When frontend means fullstack</a>, I stay on the side for a front-end that focuses on design. It doesn't mean I don't like Javascript but I believe Javascript is not only a solution. I primarily use Javascript to manipulate DOM and create micro interaction.</p>

						<p>I do love designing experience and interface, but I'm not the kind of designer who can create beautiful illustration. I know about color theory, layout principle, typography and the most important thing is I know that my design can be converted into code by someone else.</p>
												
						<p>So here I am, I'm not a Super Front-end Developer who knows everything like on the common job requirement. But if you need front-end developer who also do design, focused on CSS, care about user experience and accessibility, then you found the right one.</p>


						<p>
						I have some side project that I've been working on:
							<ol>
								<li>
									<a className="link-orange" href="http://boomkrak.com" target="_blank" rel="noopener noreferrer">Boomkrak</a>
								</li>
								<li>
									<a className="link-orange" href="http://freebbble.com" target="_blank" rel="noopener noreferrer">Freebbble</a>
								</li>
								<li>
								Else? I’m open for collaboration though :)
								</li>
							</ol>
						</p>
						<p>
						Anyway, I wrote a list what I’m currently working on, it’s easy to check my current status <Link to="/now/" className="link-orange">now</Link>.
						</p>
						<p>
						You can stalk me further on <a className="link-orange" href="https://twitter.com/nizamilputra" target="_blank" rel="noopener noreferrer">Twitter</a>, <a className="link-orange" href="https://github.com/rizqinizamil" target="_blank" rel="noopener noreferrer">Github</a>, <a className="link-orange" href="https://dribbble.com/rizqinizamil" target="_blank" rel="noopener noreferrer">Dribbble</a>, and <a className="link-orange" href="https://www.behance.net/nizamilputra" target="_blank" rel="noopener noreferrer">Behance</a>. Feel free to say hello by emailing <a href="mailto:hello@rizqi.im">hello@rizqi.im</a>.
						</p>
						<hr/>
						<p style={{fontSize: "0.875em"}}>
						<span style={{fontWeight:	"500"}}>Colophon</span><br />
						This is site is build with <a style={{color: "rebeccapurple"}} href="https://gatsby.org" target="_blank" rel="noopener noreferrer">Gatsby</a>, headless <a style={{color: "#52bee3"}} href="https://wordpress.org" target="_blank" rel="noopener noreferrer">Wordpress</a>, <a style={{color: "#d36ac2"}} href="https://emotion.sh" target="_blank" rel="noopener noreferrer">Emotion</a> and hosted on <a style={{color: "#32b4ba"}} href="https://netlify.com" target="_blank" rel="noopener noreferrer">Netlifly</a>. Thank you for awesome people for creating those awesome tools!
						</p>
					</AboutContent>
				</AboutWrapper>
			</Container> 
		</Layout>
	)
}

export default AboutPage

// Image
export const query = graphql`
  query {
	file(relativePath: { eq: "me+ramona.jpg" }) {
	  childImageSharp {
		fluid(maxWidth: 576, quality: 100) {
			...GatsbyImageSharpFluid
			presentationWidth
		}
	  }
	}
}`

